.container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
}

.content {
    text-align: center;
    padding: 2rem;
}

h1 {
    font-size: 3.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
    font-weight: 300;
}

.tagline {
    font-size: 1.2rem;
    color: #6c757d;
    margin-bottom: 2rem;
}

.contact-button {
    display: inline-block;
    padding: 1rem 2rem;
    background-color: #2c3e50;
    color: white;
    text-decoration: none;
    border-radius: 50px;
    font-size: 1.1rem;
    transition: all 0.3s ease;
}

.contact-button:hover {
    background-color: #34495e;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.company-info {
    margin-top: 3rem;
    text-align: center;
    font-size: 0.9rem;
    color: #666;
}

.company-info h2 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #333;
}

.company-info p {
    margin: 0.5rem 0;
}

.form-container {
    max-width: 500px;
    margin: 0 auto;
    background-color: white;
    padding: 2rem;
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.contact-form h2 {
    margin-bottom: 0.5rem;
    color: #2c3e50;
}

.contact-form p {
    margin-bottom: 1.5rem;
    color: #6c757d;
}

.form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.form-group label {
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #333;
}

.form-group input {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.contact-form button {
    background-color: #2c3e50;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 50px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.contact-form button:hover:not(:disabled) {
    background-color: #34495e;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-form button:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
}

.submit-message {
    margin-top: 1rem;
    font-weight: 500;
    color: #2c3e50;
}
